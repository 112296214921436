import React from 'react';
import './styles.css';

function App() {
  return (
    <div className="container">
      <div className="left-column">
        {/* Isi kolom kiri */}
      </div>
      <div className="main-column">
        {/* Isi kolom utama */}
        <img src="/img/up-1.webp" alt="Unlock Potensi" />
        <img src="/img/up-2.webp" alt="Unlock Potensi" />
        <a href="https://unlockpotensidiri.us/product/webinar-passive-income-dari-e-course/" className="button">Daftar</a>
      </div>
      <div className="right-column">
        {/* Isi kolom kanan */}
      </div>
      <a href="https://wa.me/628158000643" className="whatsapp-button" target="_blank" rel="noreferrer">
        <img src="/img/whatsapp.webp" alt="WhatsApp" />
      </a>
    </div>
  );
}

export default App;